import { Flex, Text, VStack, HStack, Img } from "@chakra-ui/react";

import { Header } from "@components/Header";

// import { Footer } from "@components/Footer";
import type { GetServerSideProps } from "next";
import { withRedirectCouponSite } from "middleware/withRedirectCouponSite";
import Head from "next/head";
// import { StandaloneLogoHeader } from "@components/StandaloneLogoHeader";
import { useMediaQuery } from "@utils/useMediaQuery";
import { Footer } from "@components/Footer";
import { SubscriptionFormContainerProps } from "@components/SubscriptionFormContainer";

export const Howdy = () => {
  const isDesktopOrSmaller: boolean = useMediaQuery(769);
  const subHeaderList = [
    {
      id: 1,
      text: <Text>Enter your phone number below</Text>,
    },
    {
      id: 2,
      text: (
        <Text>
          Purchase a bottle of Howdy!™️ Texas
          <br />
          Aggies United Sportswater at your local retailer.
        </Text>
      ),
    },
    {
      id: 3,
      text: (
        <Text>
          Text us a photo of your receipt and we&apos;ll
          <br />
          reimburse you for the cost of one (1) bottle
          <br />
          via Venmo or PayPal!
        </Text>
      ),
    },
  ];

  const headerContentCopy = (
    <Text>
      Get a free
      <br />
      bottle on us!
    </Text>
  );
  const subheaderContentCopy = (
    <VStack
      mt={"20px"}
      spacing={4}
      alignItems={["center", "center", "center"]}
      justifyContent={"center"}
      position={"relative"}
    >
      {subHeaderList.map((elem) => {
        return (
          <HStack
            key={elem.id}
            alignItems={["flex-start", "flex-start", "center", "center"]}
            justifyContent={"center"}
            spacing={4}
            ml={["0px", "0px", "0px", "30px"]}
          >
            <Flex
              borderRadius={"100%"}
              w={["22px"]}
              h={["22px"]}
              bgColor={"#FFFFFF"}
              justifyContent={"center"}
              alignItems={"center"}
              color={"#5C0025"}
              zIndex={10}
              fontFamily={"Open Sans Regular"}
              fontSize={"14px"}
              pt={"4px"}
            >
              {elem.id}
            </Flex>
            <Text
              fontFamily={"Open Sans Regular"}
              fontSize={["16px", "16px", "16px", "17px"]}
              w={["340px", "320px", "320px", "368px"]}
              textAlign={["left", "left"]}
            >
              {elem.text}
            </Text>
          </HStack>
        );
      })}
    </VStack>
  );
  const campaignPhoneNumber = "4354182269";
  // Things that will need to be added to get store locator to work
  const brandApiKey =
    "U2FsdGVkX1+VXfA82La4Nqlfl5Go+c6chYO/yDmZOcqLV2mG+/SWMAPafbCKktkJ";
  const backgroundColor = "#5C0025";
  const headerFont = "Oswald Bold";
  const legalFont = "Inter Medium";
  const headerColor = "#FFFFFF";
  const textColor = "white";
  const commonHeaderCopyStyle = {
    fontFamily: headerFont,
    textColor: headerColor,
    fontSize: ["33px", "33px", "45px"],
    fontWeight: "500",
    lineHeight: ["107%"],
    textTransform: "uppercase",
    width: ["100vw", "100vw", "454px"],
  };
  const commonSubHeaderCopyStyle = {
    color: "white",
    mx: ["auto", "auto", "auto", "auto"],
  };
  const commonButtonStyle = {
    borderRadius: "30px",
    border: "1px solid white",
    backgroundColor: "#3C001C",
    color: textColor,
    fontFamily: "Oswald Bold",
    w: ["219px", "219px", "232px"],
    height: ["47px", "47px", "50px"],
    textTransform: "uppercase",
    fontSize: ["19px", "19px", "20px"],
    fontWeight: ["500"],
    lineHeight: ["120%"],

    _hover: {
      backgroundColor: "#FEFCF4",
    },
  };

  const commonSubscriptionFormContainerProps: SubscriptionFormContainerProps = {
    campaignPhoneNumber: campaignPhoneNumber,
    // refactor for store locator
    backgroundColor: "#5C0025",
    brandApiKey,
    // end refactor for store locator
    legalCopyContainerStyle: {
      width: ["365px", "365px", "397px"],
      marginTop: ["15px", "15px"],
    },
    legalCopyStyle: {
      color: "white",
      fontFamily: legalFont,
      fontSize: ["7.5px", "7.5px", "8px"],
      lineHeight: ["150%"],
      textAlign: "center",
    },
    withLegalCopy: false,
    headerCopy: headerContentCopy,
    // @ts-expect-error strings are specific to expected values in the const object above
    headerCopyStyle: {
      ...commonHeaderCopyStyle,
      textAlign: ["center"],
    },
    subheaderCopyStyle: {
      ...commonSubHeaderCopyStyle,
    },
    formContainerStyle: {
      marginTop: ["20.02px", "30px"],
      flexDirection: "column",
      alignItems: "center",
    },
    formInputContainerStyle: {
      width: ["355px", "355px", "397px"],
    },
    formButtonStyle: {
      ...commonButtonStyle,
      textAlign: ["center"],
      textTransform: "uppercase",
      boxSizing: "border-box",
      boxShadow: "none",
    },

    formInputStyle: {
      border: ["1px solid #7C7C7C"],
      borderRadius: "0px",
      boxShadow: "none",
      boxSizing: "border-box",
      backgroundColor: "white",
      textTransform: "none",
      color: "black",
      fontFamily: "DM Sans",
      fontSize: ["17px", "17px", "18.7px"],
      textAlign: "left",
      _placeholder: {
        color: "black",
        fontFamily: "DM Sans",
        fontWeight: ["100"],
        fontSize: ["17px", "17px", "18.7px"],
        textTransform: "normal",
      },
    },
    formInputPlaceholder: "Enter your phone number...",
    withPhoneIcon: false,
    logoUrl: "/images/carl-sweat-logo.webp",
  };
  return (
    <Flex
      overflowY={"hidden"}
      direction="column"
      m="0"
      backgroundColor={backgroundColor}
    >
      <Head>
        0 <title>Howdy! | Aisle</title>
      </Head>

      <Header
        //refactor for store locator
        // customerZipCode={customerZipCode}
        // end refactor for store locator
        isDonateOptIn={false}
        subheaderCopy={subheaderContentCopy}
        {...commonSubscriptionFormContainerProps}
        imageProps={
          isDesktopOrSmaller
            ? undefined
            : { src: "/images/carlsweat-header.webp" }
        }
        imageContainerProps={{
          flexBasis: ["auto", "auto", "auto", "600px", "750px", "750px"],
          maxHeight: isDesktopOrSmaller ? "100%" : "max-content",
          minHeight: ["", "50%"],
          margin: "auto",
          pt: ["20px", "20px", "20px", "0px"],
          pb: ["20px", "20px", "20px", "0px"],
          px: ["10px", "10px", "10px", "20px", "20px"],
          //mt: ["0px", "0px", "0px", "auto", "30px", "30px"],

          //  minWidth: "100%",
        }}
        containerStyle={{
          paddingLeft: [null, null, null, "10", "10", null],

          justifyContent: {
            base: "center",
            lg: "flex-start",
          },
          marginLeft: {
            base: "0",
            lg: "auto",
          },
          flex: {
            base: "1",
            lg: "0",
          },
        }}
        formInputPlaceholder="Enter your phone number..."
        py={["0px", "0px", "0px", "60px", "60px", "60px"]}
        px={["0px", "0px", "0px", "0px", "20px"]}
        //Background sizing
        height={["100%"]}
        width={["auto"]}
        //Styling
        logoContainerStyle={{
          width: ["317px"],
          height: ["213px"],
          marginTop: ["30px", "-30px"],
          marginBottom: ["40px"],
        }}
        //Presets
        flexDirection={isDesktopOrSmaller ? ["column"] : "row"}
        optInToBrandAndAisle
        optInToBrandAndAisleTheme="light"
        optInToBrandAndAisleContainerStyle={{
          width: ["375px", "375px", "410px"],
          paddingLeft: ["0px"],
          marginTop: "-5px",
        }}
        optInToBrandAndAisleStyle={{
          fontSize: ["8px", "8px", "8px"],
        }}
        withLegalCopy={false}
        isLegalCopyAboveSubscribeButton={true}
        withPhoneIcon={false}
      />

      <Img src={isDesktopOrSmaller ? "images/carlsweat-header-mob.webp" : ""} />

      <Footer
        overscrollY={"contain"}
        h={["20px", "20px", "20px", "300px"]}
        mt={["0px", "0px", "0px", "-10px"]}
        pt={["40px", "20px", "40px", "20px"]}
        paddingBottom={["40px", "10px"]}
        bgColor={backgroundColor}
        color={textColor}
      ></Footer>
    </Flex>
  );
};

export const getServerSideProps: GetServerSideProps = withRedirectCouponSite;

export default Howdy;
