export const getTopLevelDomain = (url?: string) => {
  if (!url) {
    return "";
  }
  let rootDomain = url.trim();
  if (rootDomain.startsWith("http://")) {
    rootDomain = rootDomain.slice(7);
  } else if (rootDomain.startsWith("https://")) {
    rootDomain = rootDomain.slice(8);
  }
  if (rootDomain.startsWith("www.")) {
    rootDomain = rootDomain.slice(4);
  }
  if (rootDomain.endsWith("/")) {
    rootDomain = rootDomain.slice(0, -1);
  }
  const parts = rootDomain.split(".");
  if (parts.length > 2) {
    rootDomain = parts.slice(1).join(".");
  }
  return rootDomain.toLocaleLowerCase();
};
