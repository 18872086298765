import {
  Flex,
  FlexProps,
  HStack,
  IconButton,
  Link,
  StackDivider,
  Text,
} from "@chakra-ui/react";
import { useRouter } from "next/router";
import { FC } from "react";
import { FaInstagram } from "react-icons/fa";

interface FooterProps extends FlexProps {
  igUsername?: string | undefined;
  showLegal?: boolean;
  pb?: string;
}

export const Footer: FC<FooterProps> = ({
  igUsername,
  showLegal = false,
  pb = "73px",
  ...props
}: FooterProps) => {
  const router = useRouter();

  return (
    <Flex
      direction="column"
      alignItems="center"
      pb={pb}
      color="#667085"
      {...props}
    >
      <Flex flex="1" mb="20px" justify="center">
        <HStack spacing="10" divider={<StackDivider />}>
          <Flex>
            <Text mr="1">powered by </Text>
            <Link
              href="https://discover.gotoaisle.com/offers?code=poweredbyaisle"
              target="_blank"
              _hover={{
                color: "blue.500",
              }}
              textDecoration="underline"
            >
              aisle
            </Link>
          </Flex>
          {showLegal ? (
            <Link
              href="/terms-of-service"
              target="_blank"
              _hover={{
                color: "blue.500",
              }}
            >
              Terms of Service
            </Link>
          ) : null}
          {showLegal ? (
            <Link
              href="/platform-privacy-policy"
              target="_blank"
              _hover={{
                color: "blue.500",
              }}
            >
              Privacy Policy
            </Link>
          ) : null}
        </HStack>
      </Flex>

      {igUsername && (
        <HStack>
          {/* <Button colorScheme='facebook' leftIcon={<FaInstagram />} /> */}
          <IconButton
            icon={<FaInstagram />}
            aria-label="Instagram"
            onClick={() => {
              router.push(`https://www.instagram.com/${igUsername}/`);
            }}
          />
        </HStack>
      )}
    </Flex>
  );
};
