import { FC, useEffect } from "react";
import {
  Box,
  Flex,
  FlexProps,
  IconButton,
  HeadingProps,
  InputGroupProps,
  ButtonProps,
  InputProps,
  InputElementProps,
  TextProps,
  IconProps,
  FormControlProps,
  Image as ChakraImage,
  ImageProps,
  StackProps,
  CheckboxProps,
} from "@chakra-ui/react";
import Image from "next/image";
import { useRouter } from "next/router";
import { FaInstagram } from "react-icons/fa";
import { isMobile } from "react-device-detect";
import { useMediaQuery } from "@utils/useMediaQuery";
import { SubscriptionFormContainer } from "./SubscriptionFormContainer";
import axios from "axios";
import { GetCampaignByPhoneNumberResponse } from "pages/api/campaigns/phone-number";
import { TestModeOverlay } from "@textnology-inc/landing-page-package";
import _isNil from "lodash/isNil";
import { useJoinOffers } from "store/joinOffers.store";
import { SubscriptionContainerFields } from "./form.types";

export interface HeaderProps {
  brandApiKey: string;
  campaignPhoneNumber: string;
  headerCopy: string | JSX.Element;
  headerCopyStyle?: HeadingProps;
  subheaderCopyStyle?: HeadingProps;
  logoUrl?: string;
  logoWidth?: string;
  logoHeight?: string;
  logoContainerStyle?: HeadingProps;
  textColor?: string;
  backgroundColor?: string;
  bgImage?: string;
  productImage?: string;
  productImageWidth?: string;
  productImageHeight?: string;
  subheaderCopy?: string | JSX.Element;
  formContainerStyle?: FlexProps;
  formInputContainerStyle?: FormControlProps;
  formInputGroupStyle?: InputGroupProps;
  formInputHeight?: string | string[];
  formButtonStyle?: ButtonProps;
  formButtonCopy?: string;
  formInputStyle?: InputProps;
  formInputPlaceholder?: string;
  withLegalCopy?: boolean;
  legalCopy?: JSX.Element | string;
  imageProps?: ImageProps;
  imageContainerProps?: FlexProps;
  withPhoneIcon?: boolean;
  igUsername?: string | undefined;
  containerStyle?: FlexProps;
  formInputIconStyle?: IconProps;
  formInputIconContainerStyle?: InputElementProps;
  legalCopyStyle?: TextProps;
  legalCopyContainerStyle?: FlexProps;
  contentContainerStyle?: FlexProps;
  SMSDefaultCopy?: string;
  withProductImageBelowForm?: boolean;
  withLogoBelowForm?: boolean;
  isLegalCopyAboveInputButton?: boolean;
  isLegalCopyAboveSubscribeButton?: boolean;
  customerZipCode?: string;
  isRequireFirstName?: boolean;
  isRequireLastName?: boolean;
  isRequireEmail?: boolean;
  isRequireEmailOptional?: boolean;
  isRequireZipCode?: boolean;
  optInToBrandAndAisle?: boolean;
  optInToBrandAndAisleStyle?: TextProps;
  optInToBrandAndAisleContainerStyle?: StackProps;
  imageComponent?: React.ReactNode;
  isCampaignWithMultipleLandingPages?: boolean;
  justifyContent?: string;
  alignItems?: string;
  formFlexDirection?: string[] | string | undefined;
  isConsentRequired?: boolean;
  subscriptionFormConsentContainerStyle?: FlexProps;
  subscriptionFormCheckboxStyle?: CheckboxProps;
  successToastMessage?: string;
  optInToBrandAndAisleTheme?: "light" | "dark";
  isPepsiOptIn?: boolean;
  isDonateOptIn?: boolean;
  isDrinkingAge?: boolean;
  isLegalEnglish?: boolean;
  legalDrinkingAgeCopy?: string | React.ReactElement;
  formEmailInputPlaceholder?: string;
  customFields?: SubscriptionContainerFields[];
}

export const Header: FC<HeaderProps & FlexProps> = ({
  customerZipCode,
  campaignPhoneNumber,
  brandApiKey,
  headerCopy,
  headerCopyStyle,
  subheaderCopyStyle,
  subheaderCopy,
  logoUrl,
  logoWidth = "259.2px",
  logoHeight = "86.4px",
  logoContainerStyle,
  textColor = "#1D2939",
  backgroundColor = "#eaecf0",
  bgImage,
  productImage,
  productImageWidth = "400px",
  productImageHeight = "90.4px",
  formContainerStyle,
  formInputContainerStyle,
  formInputHeight = "44px",
  formButtonStyle,
  formButtonCopy = "Submit",
  formInputStyle,
  formInputPlaceholder = "Enter phone number...",
  withLegalCopy = true,
  legalCopy,
  withPhoneIcon,
  imageProps,
  imageContainerProps,
  igUsername,
  containerStyle,
  formInputIconStyle,
  formInputIconContainerStyle,
  legalCopyStyle,
  legalCopyContainerStyle,
  contentContainerStyle,
  SMSDefaultCopy,
  withProductImageBelowForm = false,
  withLogoBelowForm = false,
  formInputGroupStyle,
  isLegalCopyAboveInputButton = false,
  isLegalCopyAboveSubscribeButton = false,
  isRequireFirstName,
  isRequireLastName,
  isRequireEmail,
  isRequireEmailOptional,
  isRequireZipCode,
  optInToBrandAndAisle = false,
  optInToBrandAndAisleStyle,
  optInToBrandAndAisleContainerStyle,
  imageComponent,
  isCampaignWithMultipleLandingPages = false,
  justifyContent = "center",
  alignItems = "center",
  formFlexDirection = "column",
  isConsentRequired = false,
  subscriptionFormConsentContainerStyle,
  subscriptionFormCheckboxStyle,
  successToastMessage,
  optInToBrandAndAisleTheme,
  isPepsiOptIn,
  isDonateOptIn,
  isDrinkingAge,
  isLegalEnglish,
  legalDrinkingAgeCopy,
  formEmailInputPlaceholder = "Enter your email...",
  customFields,
  ...props
}) => {
  const isDesktopOrSmaller: boolean = useMediaQuery(950);
  const { setBrandName, setIsPartner } = useJoinOffers();
  const router = useRouter();
  const { referralCode, isTestMode } = router.query;
  const referralCodeText =
    typeof referralCode === "string" ? ` signupCode: *${referralCode}*` : "";

  useEffect(() => {
    if (isMobile && !!SMSDefaultCopy && router?.isReady) {
      router.push(
        `sms:${campaignPhoneNumber}?&body=${SMSDefaultCopy}${referralCodeText}`
      );
    }
  }, [SMSDefaultCopy, router, campaignPhoneNumber, referralCodeText]);

  const getCampaignByPhoneNumber = async () => {
    const campaignRequest = await axios.get<GetCampaignByPhoneNumberResponse>(
      `/api/campaigns/phone-number`,
      {
        params: {
          campaignPhoneNumber,
        },
      }
    );

    const { nameCaption, hasApprovedPartnerTerms } =
      campaignRequest.data.campaign.brand;

    setBrandName(nameCaption);
    setIsPartner(hasApprovedPartnerTerms);

    if (!campaignRequest.data || !campaignRequest.data.campaign) {
      return;
    }

    // if not in test mode AND the campaign is paused OR if the campaign endDate has passed
    // then redirect to the inactive-campaign page
    if (
      _isNil(isTestMode) &&
      // check if campaign is paused
      (campaignRequest.data.campaign.isPaused ||
        // check if endDate has passed
        (campaignRequest.data.campaign.endDate &&
          new Date() > campaignRequest.data.campaign.endDate))
    ) {
      // if user is coming from Yummers campaign
      if (campaignRequest.data.campaign.id === "Yummers15153165490") {
        router.push("/inactive-campaign-yummers");
      } else {
        router.push("/inactive-campaign");
      }
    } else if (!campaignRequest.data.campaign.isActive) {
      // NOTE: set a different behavior if inactive
      router.push("/inactive-campaign");
    }
  };

  useEffect(() => {
    getCampaignByPhoneNumber();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const subscriptionFormContainerProps = {
    campaignPhoneNumber,
    legalCopyContainerStyle,
    legalCopyStyle,
    legalCopy,
    withLegalCopy,
    brandApiKey,
    headerCopy,
    headerCopyStyle,
    subheaderCopyStyle,
    subheaderCopy,
    textColor,
    formContainerStyle,
    formInputContainerStyle,
    formInputHeight,
    formButtonStyle,
    formButtonCopy,
    formInputStyle,
    formInputPlaceholder,
    formInputGroupStyle,
    isLegalCopyAboveInputButton,
    isLegalCopyAboveSubscribeButton,
    formInputIconStyle,
    formInputIconContainerStyle,
    withPhoneIcon,
    logoUrl,
    withLogoBelowForm,
    logoWidth,
    logoHeight,
    productImage,
    withProductImageBelowForm,
    productImageWidth,
    productImageHeight,
    customerZipCode,
    isRequireFirstName,
    isRequireLastName,
    isRequireEmail,
    isRequireEmailOptional,
    isRequireZipCode,
    justifyContent,
    formFlexDirection,
  };

  return (
    <Flex
      backgroundColor={backgroundColor}
      position="relative"
      pt={["15px", "50px"]}
      pb={["15px", "50px"]}
      bg={
        bgImage
          ? `${backgroundColor} url('${bgImage}') no-repeat center center scroll`
          : undefined
      }
      bgSize="cover"
      {...props}
    >
      {isTestMode ? <TestModeOverlay /> : null}
      <Flex
        flex="1"
        align={alignItems || "center"}
        justify={justifyContent || "center"}
        zIndex="1000"
        {...containerStyle}
      >
        <Flex direction="column" {...contentContainerStyle}>
          {!!logoUrl && !withLogoBelowForm && (
            <Box
              width={logoWidth}
              m="auto"
              mb="20px"
              height={logoHeight}
              position="relative"
              {...logoContainerStyle}
            >
              <Image layout="fill" alt="aisle-logo" src={logoUrl} />
            </Box>
          )}

          {!!productImage && !withProductImageBelowForm && (
            // True size of that image is 2000px x 452px. Displaying it at 20% of those dimensions

            <Flex
              width={productImageWidth}
              m="auto"
              mb="20px"
              height={productImageHeight}
              position="relative"
            >
              <Image layout="fill" alt="aisle-logo" src={productImage} />
            </Flex>
          )}

          <SubscriptionFormContainer
            isDrinkingAge={isDrinkingAge}
            isPepsiOptIn={isPepsiOptIn}
            isDonateOptIn={isDonateOptIn}
            isLegalEnglish={isLegalEnglish}
            optInToBrandAndAisleTheme={optInToBrandAndAisleTheme}
            optInToBrandAndAisle={optInToBrandAndAisle}
            optInToBrandAndAisleStyle={optInToBrandAndAisleStyle}
            optInToBrandAndAisleContainerStyle={
              optInToBrandAndAisleContainerStyle
            }
            {...subscriptionFormContainerProps}
            isCampaignWithMultipleLandingPages={
              isCampaignWithMultipleLandingPages
            }
            isConsentRequired={isConsentRequired}
            subscriptionFormConsentContainerStyle={
              subscriptionFormConsentContainerStyle
            }
            subscriptionFormCheckboxStyle={subscriptionFormCheckboxStyle}
            successToastMessage={successToastMessage}
            legalDrinkingAgeCopy={legalDrinkingAgeCopy}
            formEmailInputPlaceholder={formEmailInputPlaceholder}
            customFields={customFields ?? []}
          />

          {!!igUsername && (
            <Flex justify="center" mt="20px">
              {/* <Button colorScheme='facebook' leftIcon={<FaInstagram />} /> */}
              <IconButton
                icon={<FaInstagram />}
                aria-label="Instagram"
                onClick={() => {
                  router.push(`https://www.instagram.com/${igUsername}/`);
                }}
              />
            </Flex>
          )}
        </Flex>
      </Flex>
      {!imageProps && imageComponent ? (
        imageComponent
      ) : (
        <Flex justifyContent={"center"} {...imageContainerProps}>
          <ChakraImage
            maxWidth={isDesktopOrSmaller ? "100%" : undefined}
            height={isDesktopOrSmaller ? "auto" : "100%"}
            {...imageProps}
          />
        </Flex>
      )}
    </Flex>
  );
};
