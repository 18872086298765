export const blockedRefererWebsitesSet = new Set([
  "doctorofcredit.com",
  "thekrazycouponlady.com",
  "lnpromos.com",
  "mysavings.com",
  "freestufffinder.com",
  "hunt4freebies.com",
  "yofreesamples.com",
  "freebie-depot.com",
  "freebieselect.com",
  "munchkinfreebies.com",
  "absolute-forum.com",
  "freebierush.com",
  "hip2save.com",
  "missfreebies.com",
  "itsafreebie.com",
  "dailyfreesamples.com",
  "freebieshark.com",
  "freebies.org",
  "sweetfreestuff.com",
  "mycouponingbuddy.com",
  "heyitsfree.net",
  "freestufftimes.com",
  "krogerkrazy.com",
  "getfreesamplesonline.com",
  "freeprizesonline.com",
  "freebiefresh.com",
  "thedailyfreebies.com",
  "freesavingmom.com",
  "freebiesfirst.com",
  "internetstealsanddeals.net",
  "freeflys.com",
  "howtoshopforfree.net",
  "ofree.net",
  "mwfreebies.com",
  "onlyfreesamples.com",
  "couponaholic.net",
  "freebiepanda.com",
  "freebiesfresh.com",
  "budgetsavvydiva.com",
  "dealmama.com",
  "freebies4mom.com",
  "freesamples.org",
  "sampleraid.com",
  "sisterssavingucents.com",
  "likeacoupon.com",
  "grabbablefreebies.com",
  "freebfinder.com",
  "toddsfreebies.com",
  "freesamplesmail.com",
  "freestufffrenzy.com",
  "onecutecouponer.com",
  "crazyforsamples.com",
  "moneysavingmom.com",
  "epicfreestuff.com",
  "dansdeals.com",
  "savewall.com",
  "tryspree.com",
  "mysavings.com",
  "redd.it",
  "reddit.com",
  "bargainbabe.com",
]);

export const wordsInDomainToRestrict = [
  "free",
  "deal",
  "coupon",
  "cheap",
  "savings",
  "money",
  "bargain",
  "freebie",
  "spree",
  "mysavings"

];
