import type { NextPage } from "next";
import { GetServerSidePropsContext } from "next";
import {
  blockedRefererWebsitesSet,
  wordsInDomainToRestrict,
} from "../utils/data/blockedRefererWebsites";
import { getTopLevelDomain } from "../utils/getTopLevelDomain";

// export type MaskerQueryParams = {
//   [key: string]: string | string[] | undefined;
// };

export type RedirectCouponSiteProps = {
  referrerUrl?: string;
  isMaskedQueryParams?: boolean;
  // maskedQueryParams?: MaskerQueryParams;
  // shouldRunQueryMask?: boolean;
};

export type NextPageWithRedirectCoupon = NextPage<RedirectCouponSiteProps>;

export const withRedirectCouponSite = async (
  { req }: GetServerSidePropsContext,
  isCouponBlockEnabled = true
  // isQueryParamsMasked = false
) => {
  // const extraProps: RedirectCouponSiteProps = {};

  // const shouldRunQueryMask =
  //   isQueryParamsMasked && Object.keys(query).length > 0;

  // if (shouldRunQueryMask) {
  //   extraProps.maskedQueryParams = { ...query };
  //   extraProps.isMaskedQueryParams;
  // }

  if (req.headers.referer && isCouponBlockEnabled) {
    const refererHostName = getTopLevelDomain(req.headers.referer);
    const isDomainInBlockList = blockedRefererWebsitesSet.has(refererHostName);
    const hasDisallowedWordInDomain = !!wordsInDomainToRestrict.find((word) =>
      refererHostName.includes(word)
    );

    if (isDomainInBlockList || hasDisallowedWordInDomain) {
      console.dir({
        note: "User was permanently redirected",
        referer: req.headers.referer,
        normalizedReferer: refererHostName,
        reason: {
          isDomainInBlockList,
          hasDisallowedWordInDomain,
        },
      });

      let destination = `/offers?referredFrom=${refererHostName}`;

      if (typeof req.url === "string") {
        const cleanUrl = encodeURIComponent(req.url);
        destination = `${destination}&referredPage=${cleanUrl}`;
      }

      return {
        redirect: {
          destination,
          permanent: false,
        },
      };
    }

    // domain is not on the blocklist
    return {
      props: {
        referrerUrl: req.headers.referer,
        // shouldRunQueryMask,
        // ...extraProps,
      },
    };
  }

  return {
    props: {
      // shouldRunQueryMask,
      // ...extraProps,
    },
  };
};
